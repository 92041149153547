import $request from "../_request";

async function suggest(data) {
    const res = await $request("/articles/v2/suggestions", {
        method: "POST",
        json: data,
    });
    return res.json();
}

suggest.quotaCost = 1;

export default suggest;
