import $request from "./_request";

const MAX_QUEUE = 100;
const MAX_WAIT = 5000; // 5 seconds

let queue = [];
let timer = null;

async function flush() {
    const data = queue;
    queue = [];
    if (timer !== null) {
        clearTimeout(timer);
        timer = null;
    }

    await $request("/analytics", {
        method: "POST",
        json: data,
    }).catch((err) => {
        console.error("Failed to push analytics event:", err);
    });
}

async function push(event) {
    queue.push({ t: Date.now(), client: "platform", ...event });

    if (queue.length >= MAX_QUEUE) {
        flush();
        return;
    }

    if (timer === null) {
        timer = setTimeout(() => flush(), MAX_WAIT);
    }
}

export default {
    push,
    flush,
};
