import { ref, watch } from "vue";
import { defineStore } from "pinia";

import { API } from "@/services/api";

import useUserStore from "./user";

const useClientConfig = defineStore("client-config", () => {
    const clientConfig = ref({});

    const userStore = useUserStore();
    watch(
        () => userStore.isLoggedIn,
        async (val) => {
            if (val) clientConfig.value = await API.etc.getClientConfig();
            else clientConfig.value = {};
        },
        { immediate: true },
    );

    return {
        clientConfig,
    };
});

export default useClientConfig;
