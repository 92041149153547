import $request from "./_request";

async function getClientConfig() {
    const res = await $request("/client-config");
    return res.json();
}

async function sendBugReport(data) {
    await $request("/bug-report", {
        method: "POST",
        json: data,
    });
}

export default {
    getClientConfig,
    sendBugReport,
};
