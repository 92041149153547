import $request from "./_request";

const DEFAULT = "_default";
const HISTORY = "_history";

async function createCollection(data) {
    const res = await $request("/collections", {
        method: "POST",
        json: data,
    });
    return res.json();
}

async function listCollections(params) {
    const res = await $request("/collections", { params });
    return res.json();
}

async function getCollection(slug) {
    const res = await $request(`/collections/${slug}`);
    return res.json();
}

async function updateCollection(slug, data) {
    const res = await $request(`/collections/${slug}`, {
        method: "PATCH",
        json: data,
    });
    return res.json();
}

async function deleteCollection(slug) {
    await $request(`/collections/${slug}`, {
        method: "DELETE",
    });
}

async function addCollectionItem(slug, data) {
    const res = await $request(`/collections/${slug}/items`, {
        method: "POST",
        json: data,
    });
    return res.json();
}

async function listCollectionItems(slug, params) {
    const res = await $request(`/collections/${slug}/items`, { params });
    return res.json();
}

async function getCollectionItem(slug, itemId) {
    const res = await $request(`/collections/${slug}/items/${itemId}`);
    return res.json();
}

async function removeCollectionItem(slug, itemId) {
    await $request(`/collections/${slug}/items/${itemId}`, {
        method: "DELETE",
    });
}

export default {
    DEFAULT,
    SAVED: DEFAULT,
    HISTORY,

    createCollection,
    listCollections,
    getCollection,
    updateCollection,
    deleteCollection,
    addCollectionItem,
    listCollectionItems,
    getCollectionItem,
    removeCollectionItem,
};
