<script setup lang="ts">
defineProps({
    options: {
        type: [Object, Array],
        required: true,
    },
    selectedValue: String,
    cramped: Boolean,
    direction: {
        type: String,
        default: "bottom",
    },
});

const emit = defineEmits(["change"]);
</script>

<template>
    <div class="options" :class="direction" @click.stop>
        <template v-if="(options instanceof Array)">
            <div
                v-for="(option, index) in options"
                :key="index"
                @click="emit('change', option)"
                class="option"
                :class="{ selected: option === selectedValue, cramped }"
            >
                <span>
                    {{ option }}
                </span>
            </div>
        </template>
        <template v-else>
            <div
                v-for="[key, value] in Object.entries(options)"
                :key="key"
                @click="emit('change', key)"
                class="option"
                :class="{ selected: key === selectedValue, cramped }"
            >
                <span v-if="typeof value === 'string'">
                    {{ value }}
                </span>
                <template v-else>
                    <img v-if="value.icon" class="option-icon" :src="value.icon" />
                    {{ value.label }}
                </template>
            </div>
        </template>
    </div>
</template>

<style scoped>
/* Options */
.options {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 5px;
    border-radius: 4px;
    box-shadow: 0px 20px 40px rgba(58, 49, 88, 0.1);
    z-index: 2147483647;
}

.options.top {
    bottom: 0;
}

.options.bottom {
    top: 0;
}

.options.left {
    right: 100%;
}

.options.right {
    left: 100%;
}

.options > * {
    padding: 6px;
}

.options.cramped > * {
    padding: 3px;
}

/* Option */

.option {
    display: flex;
    align-items: center;
    transition: linear 0.07s;
}

.option:hover {
    background-color: rgba(109, 109, 109, 0.171);
}

/* Misc */

.selected {
    background-color: #F7F5FE;
}

.option-icon {
    margin-right: 6px;
    height: 16px;
}

.option-icon.cramped {
    margin-right: 3px;
}
</style>
