import { defineStore } from "pinia";
import { ref } from "vue";

import { API } from "@/services/api";

const useUserStore = defineStore("user", () => {
    const isLoading = ref(true);
    const isLoggedIn = ref(false);
    const user = ref({});

    async function load() {
        user.value = await API.user.getCurrentUser();
        return user.value;
    }

    async function signin(data) {
        await API.$auth.signin(data);
        isLoggedIn.value = true;
        await load();
    }

    async function signup(data) {
        await API.$auth.signup(data);
        isLoggedIn.value = true;
        await load();
    }

    async function loginWithApple(data) {
        await API.$auth.loginWithApple(data);
        isLoggedIn.value = true;
        await load();
    }

    async function loginWithGoogle(data) {
        await API.$auth.loginWithGoogle(data);
        isLoggedIn.value = true;
        await load();
    }

    async function linkWithApple(data) {
        await API.user.linkWithApple(data);
        await load();
    }

    async function linkWithGoogle(data) {
        await API.user.linkWithGoogle(data);
        await load();
    }

    async function unlinkFromApple() {
        await API.user.unlinkFromApple();
        await load();
    }

    async function unlinkFromGoogle() {
        await API.user.unlinkFromGoogle();
        await load();
    }

    async function removeTokens() {
        await API.$auth.removeTokens();
        isLoggedIn.value = false;
        user.value = {};
    }

    async function logout() {
        await API.$auth.logout();
        isLoggedIn.value = false;
        user.value = {};
    }

    async function update(data) {
        user.value = await API.user.updateCurrentUser(data);
    }

    async function deleteAccount() {
        await API.user.deleteCurrentUser();
        await removeTokens();
    }

    API.$auth.isLoggedIn()
        .then(async (res) => {
            isLoggedIn.value = res;
            if (res) await load();
        })
        .catch(async (err) => {
            console.error(err);
            await logout();
            window.location.reload();
        })
        .then(() => {
            isLoading.value = false;
        });

    return {
        isLoading,
        isLoggedIn,
        user,

        signin,
        signup,
        loginWithApple,
        loginWithGoogle,
        linkWithApple,
        linkWithGoogle,
        unlinkFromApple,
        unlinkFromGoogle,
        removeTokens,
        logout,
        update,
        deleteAccount,
    };
});

export default useUserStore;
