<script setup>
import { marked } from "marked";

defineProps(["markdown", "compact"]);

// Markdown

function adaptMarkdown(data) {
    const renderer = {
        link(href, title, text) {
            return `
                <a
                    class=inline-source
                    id=inline-source
                    href=${href}
                    target="_blank"
                >
                    ${text}
                </a>
            `;
        },
        heading(text) {
            return `<h1>${text}</h1>`;
        },
        strong(text) {
            return `<strong>${text}</strong>`;
        },
    };
    marked.use({ renderer });
    const md = marked.parse(data);

    return md;
}
</script>

<template>
    <div
        v-if="markdown"
        class="markdown-result"
        :class="{ compact }"
        ref="markdownContainer"
        v-html="adaptMarkdown(markdown)"
    ></div>
</template>

<style scoped>
</style>

<style>
.markdown-result {
    word-break: break-word;
}

.markdown-result p, .markdown-result li {
    margin: 6px 0 18px 0;
}

.markdown-result .inline-source {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    letter-spacing: 0.1em;
    font-size: 75%;
    font-weight: 700;
    vertical-align: top;
    color: #9D96BA;
    text-decoration: none;
    margin-left: -3px;
    padding: 0 3px;
}

.markdown-result .inline-source:hover {
    text-decoration: underline;
}

.markdown-result.hide-inline-sources .inline-source, .compact .inline-source {
    display: none;
}

.markdown-result ol, .markdown-result ul {
    margin: 0;
    padding-left: 20px;
}

.markdown-result ol li::marker, .markdown-result ul li::marker {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
}

.markdown-result li p, .markdown-result ul p {
    margin-left: 2px;
}

.markdown-result h1 {
    font-size: 18px;
    font-weight: 700;
    color: #2D00D4;
    margin-bottom: 8px;
    margin-right: 5px;
}

.compact h1, .compact p {
    font-size: unset;
    color: unset;
    margin-bottom: unset;
}

.markdown-result strong {
    font-weight: 700;
}

pre, code {
    white-space: break-spaces;
}
</style>
