import $request from "../_request";

async function analyze(data) {
    const res = await $request("/articles/v2/analysis", {
        method: "POST",
        json: data,
    });
    return res.json();
}

async function getResult(data) {
    const res = await $request(`/articles/v2/analysis/${data.analysisId}/${data.resultId}`);
    return res.json();
}

getResult.quotaCost = 1;

export default {
    analyze,
    getResult,
};
