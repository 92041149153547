const ACCESS_TOKEN_KEY = "wiseone_access_token";
const REFRESH_TOKEN_KEY = "wiseone_refresh_token";

let $request = null;

function $inject(_request) {
    $request = _request;
}

/** @type {?string|Promise<?string>} */
let token = null;

function getToken(key) {
    const jwt = localStorage.getItem(key);
    if (jwt == null) return null;

    try {
        const payload = atob(jwt.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"));
        const { exp } = JSON.parse(payload);
        if (Date.now() / 1000 > exp) {
            console.log("Token expired");
            return null;
        }
    } catch (err) {
        console.error("Failed to decode token:", err);
        return null;
    }

    return jwt;
}

function saveTokens(tokens) {
    if (tokens == null) {
        token = null;
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
    } else {
        token = tokens.access_token;
        localStorage.setItem(ACCESS_TOKEN_KEY, tokens.access_token);
        localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refresh_token);
    }
}

token = getToken(ACCESS_TOKEN_KEY);

async function refreshTokens() {
    const refreshToken = getToken(REFRESH_TOKEN_KEY);
    if (refreshToken == null) return null;

    token = (async () => {
        try {
            const res = await $request("/auth/refresh", {
                method: "POST",
                json: {
                    refresh_token: refreshToken,
                },
                public: true,
            });
            const tokens = await res.json();
            saveTokens(tokens);
        } catch (err) {
            console.error("Token refresh failed:", err);
            saveTokens(null);
        }
        return token;
    })();

    return token;
}

async function removeTokens() {
    return saveTokens(null);
}

async function getAuthorizationToken() {
    return (await token) ?? refreshTokens();
}

async function isLoggedIn() {
    return (await getAuthorizationToken()) != null;
}

async function signin(data) {
    const res = await $request("/auth/login", {
        method: "POST",
        json: data,
        public: true,
    });
    const tokens = await res.json();
    saveTokens(tokens);
}

async function signup(data) {
    const res = await $request("/auth/signup", {
        method: "POST",
        json: data,
        public: true,
    });
    const tokens = await res.json();
    saveTokens(tokens);
}

async function loginWithApple(data) {
    const res = await $request("/auth/oauth-apple", {
        method: "POST",
        json: data,
        public: true,
    });
    const tokens = await res.json();
    saveTokens(tokens);
}

async function loginWithGoogle(data) {
    const res = await $request("/auth/oauth-google", {
        method: "POST",
        json: data,
        public: true,
    });
    const tokens = await res.json();
    saveTokens(tokens);
}

async function logout() {
    const refreshToken = getToken(REFRESH_TOKEN_KEY);
    if (refreshToken) {
        await $request("/auth/revoke", {
            method: "POST",
            json: {
                token: refreshToken,
            },
            public: true,
        });
    }
    saveTokens(null);
}

export default {
    $inject,
    refreshTokens,
    removeTokens,
    getAuthorizationToken,
    isLoggedIn,
    signin,
    signup,
    loginWithApple,
    loginWithGoogle,
    logout,
};
