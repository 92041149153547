import $openSocket from "../_socket";

async function summarize(data) {
    const socket = await $openSocket("/articles/v2/summary");

    socket.send(JSON.stringify(data));

    return socket;
}

summarize.quotaCost = 1;

export default summarize;
