import $request from "./_request";

async function getCurrentUser() {
    const res = await $request("/user");
    return res.json();
}

async function updateCurrentUser(data) {
    const res = await $request("/user", {
        method: "PATCH",
        json: data,
    });
    return res.json();
}

async function deleteCurrentUser() {
    await $request("/user", {
        method: "DELETE",
    });
}

async function linkWithApple(data) {
    await $request("/user/auths/apple", {
        method: "POST",
        json: data,
    });
}

async function linkWithGoogle(data) {
    await $request("/user/auths/google", {
        method: "POST",
        json: data,
    });
}

async function unlinkFromApple() {
    await $request("/user/auths/apple", {
        method: "DELETE",
    });
}

async function unlinkFromGoogle() {
    await $request("/user/auths/google", {
        method: "DELETE",
    });
}

async function getUserMarketingSettings() {
    const res = await $request("/user/marketing");
    return res.json();
}

async function updateUserMarketingSettings(data) {
    await $request("/user/marketing", {
        method: "PATCH",
        json: data,
    });
}

export default {
    getCurrentUser,
    updateCurrentUser,
    deleteCurrentUser,
    linkWithApple,
    linkWithGoogle,
    unlinkFromApple,
    unlinkFromGoogle,
    getUserMarketingSettings,
    updateUserMarketingSettings,
};
