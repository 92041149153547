import analysis from "./analysis";
import ask from "./ask";
import details from "./details";
import suggestions from "./suggestions";
import summary from "./summary";

export default {
    analysis,
    ask,
    details,
    suggestions,
    summary,
};
