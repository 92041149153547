<script setup lang="ts">
import { ref } from "vue";

import DropdownMenu from "./DropdownMenu.vue";

defineProps({
    modelValue: [String, Number],
    options: {
        type: Object,
        default() {
            return [];
        },
    },
    displayed: {
        type: String,
        default: null,
    },
    displayedIcon: {
        type: String,
        default: null,
    },
    direction: {
        type: String,
        default: "bottom",
    },
    disabled: Boolean,
    cramped: Boolean,
    alignText: {
        type: String,
        default: "center"
    },
});

const emit = defineEmits(["update:modelValue", "update"]);

const isOptionsOpen = ref(false);

function changeSelection(option) {
    emit("update:modelValue", option);
    emit("update");
    isOptionsOpen.value = false;
}
</script>

<template>
    <div
        class="select-container"
        :class="[{ disabled, cramped }, `align-${alignText}`]"
        @blur="isOptionsOpen = false"
        tabindex="-1"
    >
        <div @click="isOptionsOpen = true" class="select">
            <slot></slot>
            <div class="value-container">
                <div class="cur-value">
                    <img v-if="displayedIcon" :src="displayedIcon.toString()" />
                    <span>{{ displayed ?? modelValue }}</span>
                </div>
                <i v-if="isOptionsOpen && !disabled" class="material-icons">expand_less</i>
                <i v-else class="material-icons">expand_more</i>
            </div>
        </div>
        <DropdownMenu
            v-if="!disabled && isOptionsOpen"
            :options="options"
            cramped
            :direction="direction"
            @change="(value) => changeSelection(value)"
        ></DropdownMenu>
    </div>
</template>

<style scoped>
.select-container {
    position: relative;
    border: none;
    border-radius: 5px;
    padding: 6px;
    align-items: center;
    display: flex;
    cursor: pointer;
    border: 1px solid #9E97BC;
    transition: all .1s linear;
    user-select: none;
}

.select-container:hover {
    border: 1px solid #2d00d4;
}

.select-container.disabled {
    color: #00000088;
}

.select-container.cramped {
    padding: 3px;
}

.select {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.value-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}

.value-container:not(:first-child) {
    margin-left: 6px;
}

.value-container > i {
    margin-left: 5px;
}

.select-container.cramped > .value-container:not(:first-child) {
    margin-left: 3px;
}

.cur-value {
    display: flex;
    flex: 1;
}

.cur-value img {
    margin-right: 8px;
    height: 16px;
    width: 16px;
    display: inline-flex;
}

.cur-value span {
    width: 100%;
    text-align: center;
}

.select-container.align-left .cur-value span {
    text-align: start !important;
}

.select-container.align-right .cur-value span {
    text-align: end !important;
}

.options {
    right: 0;
}
</style>
