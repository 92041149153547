import $auth from "./_auth";

export class APIError extends Error {
    constructor(status, err) {
        super(err.message);
        this.status = status;
        Object.assign(this, err);
    }
}

/**
 * Make a request to the API
 * @param {string} path API route path
 * @param {RequestOptions?} options Request options
 * @returns {Promise<Response>}
 */
async function request(path, options, attempt = 1) {
    if (attempt > 3) {
        throw new Error("Something went wrong, please try again later");
    }

    const url = new URL(import.meta.env.VITE_API_URL);
    url.pathname = path.startsWith("/") ? path.slice(1) : path;

    const init = options || {};
    const headers = new Headers(init.headers);
    init.headers = headers;

    if (!init.public) {
        const token = await $auth.getAuthorizationToken();
        if (token == null) {
            throw new Error("Something went wrong, please try again later");
        }
        headers.set("Authorization", `Bearer ${token}`);
    }

    if (init.params !== undefined) {
        Object.keys(init.params).forEach((key) => url.searchParams.append(key, init.params[key]));
    }

    if (init.form !== undefined) {
        headers.set("Content-Type", "application/x-www-form-urlencoded");
        init.body = new URLSearchParams(init.form).toString();
    } else if (init.json !== undefined) {
        headers.set("Content-Type", "application/json");
        init.body = JSON.stringify(init.json);
    }

    if (init.timeout !== undefined && init.timeout > 0) {
        init.signal = AbortSignal.timeout(init.timeout);
    }

    let res;

    try {
        res = await fetch(url, init);
    } catch (err) {
        console.error(err);
        const delay = 1000 * 2 ** (attempt - 1);
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(request(path, options, attempt + 1));
            }, delay);
        });
    }

    if (!res.ok) {
        const body = await res.json();
        if (body.error === undefined) {
            throw new APIError(res.status, "unknown_error", "Unknown error");
        }

        if (["invalid_token", "unauthorized"].includes(body.error) && !init.public) {
            const newToken = await $auth.refreshTokens();
            if (newToken != null) return request(path, options, attempt + 1);
        }

        throw new APIError(res.status, body);
    }

    return res;
}

$auth.$inject(request, APIError);

export default request;
