import $request from "./_request";

async function authorize(data) {
    const res = await $request("/auth/authorize", {
        method: "POST",
        json: data,
    });
    return res.json();
}

async function resetPassword(data) {
    await $request("/auth/reset-password", {
        method: "POST",
        json: data,
        public: true,
    });
}

async function confirmEmail(data) {
    await $request("/auth/confirm-email", {
        method: "POST",
        json: data,
        public: true,
    });
}

export default {
    authorize,
    resetPassword,
    confirmEmail,
};
