import $openSocket from "../_socket";

async function ask(data) {
    const socket = await $openSocket("/articles/v2/ask");

    socket.send(JSON.stringify(data));

    return socket;
}

ask.quotaCost = 1;

export default ask;
