import $request from "./_request";

async function getBillingInfo() {
    const res = await $request("/billing");
    return res.json();
}

async function getQuotaInfo() {
    const res = await $request("/billing/quota");
    return res.json();
}

async function createCheckoutSession(data) {
    const res = await $request("/billing/checkout", {
        method: "POST",
        json: data,
    });
    return res.json();
}

async function createBillingPortalSession() {
    const res = await $request("/billing/portal", {
        method: "POST",
    });
    return res.json();
}

export default {
    getBillingInfo,
    getQuotaInfo,
    createCheckoutSession,
    createBillingPortalSession,
};
