import $auth from "./_auth";
import { APIError } from "./_request";

async function waitForOpenConnection(socket) {
    return new Promise((resolve, reject) => {
        socket.addEventListener("open", () => {
            resolve();
        }, { once: true });

        socket.addEventListener("error", () => {
            reject(new Error("Could not connect to server"));
        }, { once: true });
    });
}

export async function openSocket(path) {
    const url = new URL(import.meta.env.VITE_API_URL);
    url.protocol = "wss:";
    url.pathname = path.startsWith("/") ? path.slice(1) : path;

    const token = await $auth.getAuthorizationToken();
    if (token == null) throw new Error("Something went wrong, please try again later");
    url.searchParams.append("token", token);

    const socket = new WebSocket(url);

    await waitForOpenConnection(socket);

    const message = await new Promise((resolve) => {
        socket.addEventListener("message", (m) => resolve(m), { once: true });
    });
    const messageData = JSON.parse(message.data);

    if (messageData.error !== undefined) {
        if (messageData.error === "invalid_token") {
            await $auth.logout();
        } else if (messageData.error === "unauthorized") {
            const newToken = await $auth.refreshTokens();
            if (newToken != null) return openSocket(path);
        }
        throw new APIError(-1, messageData);
    }

    return socket;
}

export default openSocket;
